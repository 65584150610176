import { benchmarkValue, monitoringValue } from './../graphs/graphsStructure'
import {
  detailedOccupancyValue,
  monthlyValue,
  stackedColumnValue,
  topFlopValue,
  RIE,
  frequentationValue,
  spaceAttendanceValue,
  utilizationQualityValue,
  detailedOccupancyTableValue,
  heatmapValue,
  calibratedUtilizationValue,
  roomBookingValue,
  countingMaxValue,
} from '../graphs/graphsStructure'

export type EnvConfig = {
  env: {
    SERVER_PREFIX: {
      user: string
      localization: string
      graph: string
      savedReport: string
      dashboard: string
      noSsoRedirectURI: string
      gateway: string
    }
    JIMO_ID: string
    EXTERNAL_LINKS: {
      PORTAL: string
    }
  }
}

export const serverPrefixes = (window as unknown as EnvConfig).env.SERVER_PREFIX
export const externalLinks = (window as unknown as EnvConfig).env.EXTERNAL_LINKS

export const API = {
  AUTH: {
    PRE_LOGIN: `${serverPrefixes.user}/api/oauth/pre-login`,
    LOGIN_SSO: `${serverPrefixes.user}/api/oidc/login`,
    TOKEN: `${serverPrefixes.user}/api/oauth/token`,
    REFRESH_TOKEN: `${serverPrefixes.user}/api/oauth/token`,
    FORGOT_PASSWORD: `${serverPrefixes.user}/api/v3/user/reset-password`,
    RESET_PASSWORD: `${serverPrefixes.user}/api/v3/user/update-password`,
    CHANGE_PASSWORD: `${serverPrefixes.user}/api/v3/user/current/password`,
    USER_CURRENT: `${serverPrefixes.user}/api/v3/user/current`,
    SEND_CODE: `${serverPrefixes.user}/api/v3/user/otp`,
  },
  ANALYSIS: {
    FILTER: `${serverPrefixes.localization}/api/v1/:clientCode/domains-structure`,
    SAVE: `${serverPrefixes.savedReport}/api/v1/:clientCode/report`,
    [topFlopValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/top_flop`,
    [stackedColumnValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/utilization_of_space`,
    [detailedOccupancyValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/detailed_occupancy`,
    [detailedOccupancyTableValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/detailed_occupancy_table`,
    [heatmapValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/heatmap`,
    [monthlyValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/occupancy_monthly`,
    [RIE]: `${serverPrefixes.graph}/api/v1/:clientCode/data/restaurant_max_cumulation`,
    [frequentationValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/restaurant_attendance_day`,
    [spaceAttendanceValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/building_attendance_crosstab`,
    [utilizationQualityValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/collaboration_utilization_quality`,
    [monitoringValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/monitoring_table`,
    [calibratedUtilizationValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/calibrated_utilization`,
    [roomBookingValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/room_booking`,
    [benchmarkValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/benchmark`,
    [countingMaxValue]: `${serverPrefixes.graph}/api/v1/:clientCode/data/counting_max`,
    RIE_VIEW: `${serverPrefixes.gateway}/api/v1/:clientCode/localization/:path`,
    LOCALIZATION: `${serverPrefixes.localization}/api/v1/:clientCode/localization/:path`,
    LOCALIZATION_GW: `${serverPrefixes.gateway}/api/v1/:clientCode/localization/:path`,
    LOCALIZATION_STATUS: `${serverPrefixes.localization}/api/v1/:clientCode/localization/:path/status`,
    LOCALIZATION_STATUS_GW: `${serverPrefixes.gateway}/api/v1/:clientCode/localization/:path/status`,
    RIE_VIEW_STATUS: `${serverPrefixes.gateway}/api/v1/:clientCode/localization/:path/status`,
    RIE_VIEW_CLIENT_DISPLAY: `${serverPrefixes.gateway}/api/v1/:clientCode/display`,
    MAP: `${serverPrefixes.localization}/api/v1/:clientCode/localization/:path/map`,
    LANDING_PAGE: `${serverPrefixes.graph}/api/v1/:clientCode/data/landing_page`,
    LANDING_PAGE_ATTENDANCE: `${serverPrefixes.graph}/api/v1/:clientCode/data/landing_page/restaurant_attendance`,
  },
  SAVED_REPORTS: {
    GET_ALL: `${serverPrefixes.savedReport}/api/v1/:clientCode/report`,
    GET_ONE: `${serverPrefixes.savedReport}/api/v1/:clientCode/report/:id`,
    UPDATE: `${serverPrefixes.savedReport}/api/v1/:clientCode/report/:id`,
    DELETE: `${serverPrefixes.savedReport}/api/v1/:clientCode/report/:id`,
    DUPLICATE: `${serverPrefixes.savedReport}/api/v1/:clientCode/report/:id/duplicate`,
    NEWDASHBOARD: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard`,
    EXISTING_DASHBOARD: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/:dashboardId/report/:reportId`,
    GET_ALL_DASHBOARDS: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/`,
    GET_DASHBOARD: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/:dashboardId`,
    DELETE_DASHBOARD: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/:id`,
    DUPLICATE_DASHBOARD: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/:id/duplicate`,
    EDIT_DASHBOARD: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/:id`,
    ADD_DASHBOARD_TO_HOMEPAGE: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/:id/homepage`,
    GET_HOMEPAGE_DASHBOARD: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/homepage`,
    GET_AUTHORIZED_DASHBOARD: `${serverPrefixes.savedReport}/api/v1/:clientCode/report/:reportId/dashboard`,
    ADD_AUTOMATIC_SENT: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/:id/automatic_sending`,
    GET_AUTOMATIC_SENT: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/:id/automatic_sending`,
    SEND_MAIL: `${serverPrefixes.savedReport}/api/v1/dashboard/:id/send`,
    INSTANT_MAIL: `${serverPrefixes.savedReport}/api/v1/:clientCode/dashboard/:id/instant_email`,
  },
  SITES: {
    GET_ONE: `${serverPrefixes.savedReport}/api/v1/:clientCode/data/id_card/:domainPath`,
    GET_PICTURE: `${serverPrefixes.savedReport}/api/v1/:clientCode/data/id_card/:domainPath/picture`,
    EDIT: `${serverPrefixes.savedReport}/api/v1/:clientCode/data/id_card/:domainPath`,
    EDIT_PICTURE: `${serverPrefixes.savedReport}/api/v1/:clientCode/data/id_card/:domainPath/picture`,
  },
  CONFIGURATION: {
    COLORS: `${serverPrefixes.savedReport}/api/v1/:clientCode/configuration/color`,
  },
}
